<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      color="blue darken-4"
      icon="fa fa-list-ul"
      title="已删除项目"
      class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
    >
      <v-row :class="isXs?'titleSearch mt-2':'mt-2'">
        <v-col
          cols="6"
          sm="10"
          md="10"
          lg="10"
          xl="10"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-text-field
            v-model="name"
            label="项目名称"
            solo
            dense
            clearable
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-btn
            elevation="1"
            color="blue mt-0"
            style="max-width:100%;"
            @click="searchBtn"
          >
            <v-icon left>
              fa4 fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr
            :class="isXs ? 'titles' : ''"
          >
            <th
              style="min-width:40px"
            >
              序号
            </th>
            <th style="min-width:140px">
              项目名称
            </th>
            <th
              style="min-width:84px"
            >
              所属分类
            </th>
            <th
              style="min-width:84px"
            >
              填报用户
            </th>
            <th
              style="min-width:100px"
            >
              创建时间
            </th>
            <th
              style="min-width:100px"
            >
              删除用户
            </th>
            <th
              style="min-width:100px"
            >
              删除时间
            </th>
            <th
              style="min-width:150px"
              class="text-right"
            >
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(delProject, index) in deleteList"
            :key="index"
            :class="isXs ? 'names' : ''"
          >
            <td>{{ index + (pageNum - 1) * 20 + 1 }}</td>
            <td>
              <router-link
                class="blue--text text--darken-4"
                :to="`/report-details/${delProject.formId}/${delProject.id}`"
              >
                {{ delProject.name }}
              </router-link>
            </td>
            <td>
              {{ getType(delProject.formId) }}
            </td>
            <td>
              {{ delProject.user.nickName }}
            </td>
            <td>{{ delProject.createdAt | dateformat }}</td>
            <td>
              {{ delProject.info.nickname }}
            </td>
            <td>{{ delProject.deletedAt | dateformat }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1 my-1"
                min-width="0"
                small
                color="success"
                @click="seeProject(delProject.formId, delProject.id)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
              <v-btn
                class="px-2 ml-1 my-1"
                min-width="0"
                small
                color="#FF8F00"
                @click="restoreProject(delProject.id)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-reply-all
                </v-icon>
                恢复
              </v-btn>
              <v-btn
      					v-permission="['ROLE_ADMIN']"
                class="px-2 ml-1 my-1"
                min-width="0"
                small
                color="error"
                @click="deleteMobelBtn(delProject.id)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                彻底删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="pageNum"
        :length="pageLenght"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      deleteList: [],
      pageNum: 1,
      pageLenght: 0,
      level: '',
      name: '',
      formType: [],
      formTypes: [],
      isXs: false,
    }),
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      if (JSON.parse(localStorage.newForms).length) {
        const resp = JSON.parse(localStorage.newForms)[0].children
        this.formTypes = resp
        resp.map(val => {
          this.formType.push(val.id)
        })
      }
      this.pageNum = 1
      await this.getDelete()
      this.level = localStorage.level
      if (this.$route.params.page) {
        this.pageNum = parseInt(this.$route.params.page)
      }
			await this.getUserInfo()
    },
    methods: {
      getType (formId) {
        for (let inx = 0; inx < this.formTypes.length; inx++) {
          if (this.formTypes[inx].id === formId) {
            return this.formTypes[inx].name
          }
        }
      },
      searchBtn () {
        this.pageNum = 1
        this.getDelete()
      },
      pageChange () {
        this.$router.push({ path: `/delete-project-list/${this.pageNum}` })
        this.getDelete()
      },
      async getDelete () {
        await this.$axios.get('/reports/deleted', {
          params: {
            formId: this.formType.toString(),
            page: this.pageNum - 1,
            size: 20,
            name: this.name,
          },
        })
          .then(res => {
            this.deleteList = res.data.data.content
            this.pageLenght = res.data.data.totalPages
            console.log(this.deleteList)
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getUserInfo () {
        const _this = this
        this.deleteList.map(async function (val) {
          await _this.$axios.get(`/users/${val.deletedBy}/show`)
            .then(res => {
              _this.$set(val, 'info', res.data.data)
            })
        })
      },
      seeProject (formId, id) {
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: formId,
            id: id,
          },
        })
      },
      // 删除按钮弹出弹框
      deleteMobelBtn (index) {
        this.$swal({
          title: '你确定要彻底删除此项目吗？',
          text: '项目删除后再也无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(index)
          }
        })
      },
      deleteProject (index) { // 删除项目
        this.$axios.delete(`reports/${index}/destroy`)
          .then((response) => {
            this.deleteList.splice(index + 1, 1)
            this.getDelete()
            this.$swal({
              title: '彻底删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },
      restoreProject (id) {
        this.$axios.put(`/reports/${id}/restore`)
          .then(res => {
            this.$swal({
              title: '项目恢复成功。',
              icon: 'success',
              confirmButtonText: '确定',
            })
            this.getDelete()
          })
          .catch(err => {
            console.log(err)
            this.$swal({
              title: '项目恢复失败。',
              icon: 'error',
              confirmButtonText: '确定',
            })
          })
      },
    },
  }
</script>
<style lang="scss" scoped>

.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
::v-deep .titleSearch .v-text-field__details{
display:none
}
</style>
